
const je = require('json-encrypt')
let user_permissions = window.localStorage.getItem("user_permissions");
user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));

var modules = [{
  _name: 'CSidebarNavItem',
  name: 'Inicio',
  to: '/inicio/inicio',
  icon: 'cilHome',
}];


if(user_permissions.indexOf('BoxList') > - 1 || user_permissions.indexOf('IncomeList') > - 1  || user_permissions.indexOf('ExpenseList') > - 1){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('BoxList') > -1) {
    item.push({ name: 'Listar', to: '/caja/listar' });
  }
  if (user_permissions.indexOf('BoxAdd') > -1) {
    item.push({ name: 'Caja', to: '/caja/nuevo' });
  }
  if (user_permissions.indexOf('IncomeList') > -1) {
    item.push({ name: 'Ingreso Administrativo', to: '/ingreso-administrativo/listar' });
  }
  if (user_permissions.indexOf('ExpenseList') > -1) {
    item.push({ name: 'Egreso Administrativo', to: '/egreso-administrativo/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Caja',
    icon: 'cilCash',
    items: item
  }
  modules.push(TypeUser);
}




if(user_permissions.indexOf('ClientList') > -1  ){
    let TypeUser = {
      _name: 'CSidebarNavItem',
      name: 'Clientes',
      to: '/cliente/listar',
      icon: 'cil-people',
    }
    modules.push(TypeUser);
}

if(user_permissions.indexOf('GuarantorList') > -1  ){
  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Garantes',
    to: '/garante/listar',
    icon: 'cil-people',
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('RequirementList') > -1  ){
  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Requisitos',
    to: '/requisito/listar',
    icon: 'cil-people',
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('PreEvaluationList') > - 1 || user_permissions.indexOf('CreditList') > - 1 || user_permissions.indexOf('SimulationList') > - 1
 || user_permissions.indexOf('DisbursementList') > - 1 || user_permissions.indexOf('CreditPaymentList') > - 1 || user_permissions.indexOf('InstallmentAmortizationList') > - 1
 || user_permissions.indexOf('CredittAmortizationList') > - 1 || user_permissions.indexOf('CreditFeeReductionList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('PreEvaluationList') > -1) {
    item.push({ name: 'Equifax', href: 'https://soluciones.equifax.com.pe/efx-portal-web/', target: '_blank' });
  }
  if (user_permissions.indexOf('SimulationList') > -1) {
    item.push({ name: 'Simulación', to: '/simulador/listar' });
  }
  if (user_permissions.indexOf('CreditList') > -1) {
    item.push({ name: 'Créditos', to: '/credito/listar' });
  }
  if (user_permissions.indexOf('DisbursementList') > -1) {
    item.push({ name: 'Desembolso', to: '/desembolso/listar' });
  }
  if (user_permissions.indexOf('CreditPaymentList') > -1) {
    item.push({ name: 'Pagos', to: '/pago-credito/listar' });
  }
  if (user_permissions.indexOf('InstallmentAmortizationList') > -1) {
    item.push({ name: 'Amortización de Cuota', to: '/amortizacion-cuota/listar' });
  }
  if (user_permissions.indexOf('CreditAmortizationList') > -1) {
    item.push({ name: 'Amortización de Credito', to: '/amortizacion-credito/listar' });
  }
  if (user_permissions.indexOf('CreditFeeReductionList') > -1) {
    item.push({ name: 'Reducción de Cuotas', to: '/reduccion-cuotas/listar' });
  }
  if (user_permissions.indexOf('CreditRefinanceList') > -1) {
    item.push({ name: 'Refinanciar Crédito', to: '/refinanciar-credito/listar' });
  }
 

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Créditos',
    icon: 'cilDollar',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('ReportUserBalanceList') > -1 
|| user_permissions.indexOf('ReportBalanceBadDebtsList') > -1 
|| user_permissions.indexOf('ReportCreditDisbursementList') > -1
|| user_permissions.indexOf('ReportIncomeOfTheDayList') > -1
|| user_permissions.indexOf('ReportDelinquentBalanceSummaryList') > -1
|| user_permissions.indexOf('ReportCollectionGeneralList') > -1
|| user_permissions.indexOf('ReportMypeMonthIncomeDetailsList') > -1
|| user_permissions.indexOf('ReportDetailsOfAgriculturalMonthIncomePersonalList') > -1
|| user_permissions.indexOf('ReportSalesList') > -1
|| user_permissions.indexOf('ReportMonthlyIncomeList') > -1
|| user_permissions.indexOf('ReportCreditsToExpireList') > -1
|| user_permissions.indexOf('ReportClientsList') > -1
|| user_permissions.indexOf('ReportCreditDisbursementList') > -1
|| user_permissions.indexOf('ReportCreditHistoryList') > -1
){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ReportUserBalanceList') > -1) {
    item.push({ name: 'Saldo de Usuarios', to: '/reporte/saldo-usuario' });
  }
  if (user_permissions.indexOf('ReportBalanceBadDebtsList') > -1) {
    item.push({ name: 'Saldo de morosos', to: '/reporte/saldo-morosos' });
  }
  if (user_permissions.indexOf('ReportCreditDisbursementList') > -1) {
    item.push({ name: 'Desembolso de Créditos', to: '/reporte/desembolso-creditos' });
  }
  if (user_permissions.indexOf('ReportIncomeOfTheDayList') > -1) {
    item.push({ name: 'Ingresos Del Día', to: '/reporte/ingresos-del-dia' });
  }
  if (user_permissions.indexOf('ReportDelinquentBalanceSummaryList') > -1) {
    item.push({ name: 'Resumen de Saldo Morosos', to: '/reporte/resumen-de-saldo-morosos' });
  }
  if (user_permissions.indexOf('ReportCollectionGeneralList') > -1) {
    item.push({ name: 'General de Cobranza', to: '/reporte/general-de-cobranza' });
  }
  if (user_permissions.indexOf('ReportMypeMonthIncomeDetailsList') > -1) {
    item.push({ name: 'Detalle Ingresos Mes Mype', to: '/reporte/ingresos-mes-mype' });
  }
  if (user_permissions.indexOf('ReportDetailsOfAgriculturalMonthIncomePersonalList') > -1) {
    item.push({ name: 'Detalle Ingresos Mes Agropecuario - Personal', to: '/reporte/ingresos-mes-agropecuario-personal' });
  }
  if (user_permissions.indexOf('ReportSalesList') > -1) {
    item.push({ name: 'Ventas', to: '/reporte/ventas' });
  }
  if (user_permissions.indexOf('ReportMonthlyIncomeList') > -1) {
    item.push({ name: 'Ingresos Mensual', to: '/reporte/ingreso-mensual' });
  }
  if (user_permissions.indexOf('ReportCreditsToExpireList') > -1) {
    item.push({ name: 'Créditos por Vencerse', to: '/reporte/creditos-por-vencerse' });
  }
  if (user_permissions.indexOf('ReportClientsList') > -1) {
    item.push({ name: 'Clientes', to: '/reporte/clientes' });
  }
  if (user_permissions.indexOf('ReportCreditHistoryList') > -1) {
    item.push({ name: 'Historial de Crédito', to: '/reporte/historial-credito' });
  }

  
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Reporte',
    icon: 'cilFile',
    items: item
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('UserList') > -1 || user_permissions.indexOf('UserTypeList') > -1  || user_permissions.indexOf('VoucherList') > -1 || user_permissions.indexOf('BusinessList') > -1 
|| user_permissions.indexOf('FinancialData') > -1 || user_permissions.indexOf('BranchOfficeList') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('UserList') > -1) {
    item.push({ name: 'Usuario', to: '/usuario/listar' });
  }
  if (user_permissions.indexOf('UserTypeList') > -1) {
    item.push({ name: 'Tipo de Usuario', to: '/tipo-usuario/listar'});
  }
  if (user_permissions.indexOf('VoucherList') > -1) {
    item.push({ name: 'Comprobante', to: '/comprobante/listar'});
  }
  if (user_permissions.indexOf('FinancialDataList') > -1) {
    item.push({ name: 'Dato Financiero', to: '/dato-financiero/listar'});
  }
  if (user_permissions.indexOf('BranchOfficeList') > -1) {
    item.push({ name: 'Sucursal', to: '/sucursal/listar'});
  }
  if (user_permissions.indexOf('BusinessList') > -1) {
    item.push({name: 'Empresa',to: '/empresa/editar'});
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Mantenimiento',
    icon: 'cilCog',
    items: item
  }
  modules.push(TypeUser);
}

export default [
  {
    _name: 'CSidebarNav',
    _children: modules
  }
]